* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: none;
  font: inherit;
  outline: none;
}

html,
body {
  background: #22252b;
  color: #fff;
  font: normal 18px/1.25 "IBM Plex Sans", Arial, Helvetica, sans-serif;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 40px 20px;
}

.content {
  width: 100%;
  max-width: 540px;
}

.header {
  margin-bottom: 32px;
}

.header__logo {
  font-size: 24px;
  margin-bottom: 8px;
}

.header__slogan {
  color: #a4a4a7;
}

.options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
}

.options__toggles {
  display: flex;
  align-items: center;
}

.options__modes {
  display: flex;
}

.mode {
  margin-left: 1px;
}

.mode__control {
  display: block;
  padding: 6px 12px;
  background-color: #2c3036;
  cursor: pointer;
}

.mode:first-child .mode__control {
  border-radius: 18px 0 0 18px;
  padding-left: 16px;
}

.mode:last-child .mode__control {
  border-radius: 0 18px 18px 0;
  padding-right: 16px;
}

.mode__input {
  display: none;
}

.mode__input:checked + .mode__control {
  background-color: #0087ff;
}

.password {
  position: relative;
  margin-bottom: 20px;
}

.password__value {
  display: block;
  width: 100%;
  padding: 28px 20px;
  border-radius: 6px;
  background: #2c3036;
  color: #fff;
  font-size: 40px;
  font-family: "IBM Plex Mono", monospace;
  text-align: center;
}

.password__value::selection {
  background-color: rgba(130, 180, 255, 0.7);
  color: #fff;
}

.password__copy {
  position: absolute;
  display: flex;
  padding: 12px;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background: none;
  opacity: 0.7;
}

.password__copy:hover {
  opacity: 1;
}

.password__copy:active {
  transform: translateY(1px);
}

.button {
  padding: 16px 24px;
  cursor: pointer;
  color: #fff;
  background: #0087ff;
  border-radius: 4px;
}

.button:hover {
  background-color: #1490ff;
}

.button:active {
  transform: translateY(1px);
}

.toggle {
  display: flex;
  align-items: center;
  margin-left: 20px;
  user-select: none;
}

.toggle:first-child {
  margin-left: 0;
}

.toggle__input {
  display: none;
}

.toggle__control {
  position: relative;
  margin-right: 8px;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background-color: #3d3f44;
  transition: background-color 0.25s;
  cursor: pointer;
}

.toggle__input:checked + .toggle__control {
  background-color: #0087ff;
}

.toggle__input:disabled + .toggle__control,
.toggle__input:disabled + .toggle__control + .toggle__label {
  opacity: 0.5;
  cursor: default;
}

.toggle__control:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  border-radius: 8px;
  background-color: #fff;
  transition: transform 0.25s;
}

.toggle__input:checked + .toggle__control:before {
  transform: translateX(20px);
}

.footer {
  display: flex;
  margin-top: 40px;
  border-top: 1px solid #2c3036;
  padding-top: 20px;
  font-size: 16px;
}

.footer__link {
  margin-right: 16px;
  color: #a4a4a7;
  text-decoration: none;
}

.footer__link:hover {
  color: #fff;
}

@media (max-width: 767px) {
  body {
    font-size: 16px;
  }

  .password__value {
    font-size: 24px;
    padding: 24px 16px;
  }

  .password__copy {
    display: none;
  }

  .options {
    flex-direction: column;
    align-items: flex-start;
  }

  .options__modes {
    order: -1;
    margin-bottom: 16px;
  }
}
